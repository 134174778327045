import React, { ReactText, useEffect, useMemo, useState, useRef } from 'react';
import styles from './SearchSelect.module.scss';
import { IState, IUncCells } from '@root-gipro/store/interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { setUncCellsFullInfo } from '@root-gipro/modules/userProjects/store/actions';

interface Props {
	uncCells: IUncCells[];
	refreshFilterValue: (fieldName: string, value: string[] | string | undefined | boolean) => void;
}

const SearchSelect: React.FC<Props> = ({ uncCells, refreshFilterValue }) => {
	const dispatch = useDispatch();
	const options: IUncCells[] = uncCells;
	const [searchTerm, setSearchTerm] = useState<string>('');
	const [selectedOption, setSelectedOption] = useState<IUncCells | null>(null);
	const [hasMatches, setHasMatches] = useState(true);
	const [focusToInput, setFocusToInput] = useState(false);
	const [visibleCount, setVisibleCount] = useState(20); // Начальное количество отображаемых элементов
	const observerRef = useRef<HTMLDivElement | null>(null);
	const listRef = useRef<HTMLUListElement | null>(null); // реф для списка
	const uncCodes = useSelector((state: IState) => state.filter.uncCodes);

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(() => event?.target?.value);
		setSelectedOption(() => null);
		const searched = options.find(item => item.code.toLowerCase() === event.target.value.toLowerCase());
		if (searched) {
			setSelectedOption(() => searched);
		}
	};

	const handleSearchClick = (e: any) => {
		e.preventDefault();
		dispatch(setUncCellsFullInfo(selectedOption?.id as ReactText));
		setSearchTerm('');
		setSelectedOption(null);
	};

	const handleSelectUncCodes = (unc: IUncCells) => {
		setSelectedOption(unc);
		setSearchTerm(unc.code);
	};

	useEffect(() => {
		if (searchTerm) {
			if (options.length) {
				setHasMatches(
					!!options.find(item =>
						item.code.toLowerCase().includes(searchTerm.toLowerCase())
					)
				);
			}
		} else {
			setHasMatches(true);
		}
	}, [options, searchTerm]);

	useEffect(() => {
		if (uncCodes.length) {
			refreshFilterValue('uncCellId', uncCodes.map(item => item.id).join(','));
		}
	}, [uncCodes]);

	// Фильтрация по поиску
	const uncOptions = useMemo(() => {
		return options.filter(option =>
			option?.code?.toLowerCase()?.includes(searchTerm?.toLowerCase())
		);
	}, [searchTerm,focusToInput]);

	// Функция загрузки дополнительных элементов при скролле
	const loadMoreItems = () => {
		setVisibleCount(prev => prev + 20); //  ещё 20 элементов
	};

	// Подключение Intersection Observer
	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				if (entries[0].isIntersecting) {
					loadMoreItems();
				}
			},
			{
				root: listRef.current, 
				rootMargin: '10px',
				threshold: 1.0
			}
		);

		if (observerRef.current) {
			observer.observe(observerRef.current);
		}

		return () => {
			if (observerRef.current) {
				observer.unobserve(observerRef.current);
			}
		};
	}, [uncOptions.length,focusToInput]);

	return (
		<div className={styles.searchSelect}>
			<input
				type="text"
				placeholder="Поиск..."
				value={searchTerm}
				onChange={handleInputChange}
				className={`${styles.searchInput} ${!hasMatches && styles.errorInput}`}
				onBlur={() => setTimeout(() => setFocusToInput(false), 200)}
				onFocus={() => setFocusToInput(true)}
			/>
			{hasMatches && focusToInput && (
				<ul ref={listRef} className={styles.optionList}>
					{uncOptions.slice(0, visibleCount).map((option: IUncCells) => (
						<li
							key={option.id}
							onClick={() => handleSelectUncCodes(option)}
							className={`${styles.optionItem} ${
								uncCodes.find(item => item.id.toString() === option.id.toString()) && styles.disableOption
							}`}
						>
							{option.code}
						</li>
					))}
					{/* Элемент для слежения за скроллом */}
					<div ref={observerRef} className={styles.observerTrigger}></div>
				</ul>
			)}
			<button onClick={handleSearchClick} className={styles.searchButton} disabled={!selectedOption}>
				Применить
			</button>
		</div>
	);
};

export default SearchSelect;
