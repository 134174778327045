import {
	getSelectCommonProject,
	getUncsCommonProject,
	getUncTechnicalSpecificationsCommonProject,
	getUnnormalizedCommonProjects,
	setCommonUncsPaginationCount,
	setSpecificationUncsPaginationCount,
} from '@root-gipro/modules/commonProjects/store/actions'
import { PurchasesTypeEnum } from '@root-gipro/modules/purchases/models/enums/purchases-type.enum'
import { Routes } from '@root-gipro/router'
import { BreadcrumbsComponent } from '@root-gipro/shared/BreadcrumbsComponent/BreadcrumbsComponent'
import { usePageLink } from '@root-gipro/shared/hooks/usePageLink'
import { TypesNavLink } from '@root-gipro/shared/TabsComponents/tabs.props'
import { IState } from '@root-gipro/store/interfaces/state'
import { searchByKey } from '@root-gipro/utils/helpers/common'
import React, { FC, memo, MutableRefObject, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

const SelectedContainer: FC = memo(({ children }) => {
	const { originalObj } = useSelector((state: IState) => state.userProjects)
	const { selectedProject, unnormalizedData, paginationUncsCounter, specificationUncsPaginationCount } = useSelector(
		(state: IState) => state.commonProjects
	)
	const { selectedPurchase } = useSelector((state: IState) => state.purchases)
	const { groups, company } = useSelector((state: IState) => state.public)
	const [typesRoute, setTypesRoute] = useState<TypesNavLink[]>()
	const [group, setGroup] = useState<number>()
	const { pathRoute, nameLink }: { currentPath: string; pathRoute: string; nameLink: string } = usePageLink()
	const { pathname } = useLocation()
	const dispatch = useDispatch()
	const urlId: MutableRefObject<string | undefined> = useRef()
	const [isFetched, setIsFetched] = useState({ main: false, rates: false, specifications: false, unnormalized: false })

	useEffect(() => {
		urlId.current = pathname.replace(/[^\d]/g, '')
		const id = urlId.current
		if (id) {
			dispatch(getUnnormalizedCommonProjects(id as string))
		}
	}, [])

	useEffect(() => {
		urlId.current = pathname.replace(/[^\d]/g, '')
		const checkUrl =
			pathname.includes('parameters') || pathname.includes('rates') || pathname.includes('construction-resources')
		try {
			if (pathRoute === Routes.COMMON_PROJECTS && checkUrl && urlId.current) {
				dispatch(setCommonUncsPaginationCount(0))
				if (pathname.includes('rates') && !isFetched.rates) {
					if (selectedProject?.unc_sort == 2024) {
						dispatch(getUncsCommonProject(urlId.current, 100, paginationUncsCounter, false, true))
					} else if (selectedProject?.unc_sort) {
						dispatch(getUncsCommonProject(urlId.current, undefined, undefined, false, true))
					}
					setIsFetched(prev => ({ ...prev, rates: true }))
				}
				if (pathname.includes('construction-resources') && !isFetched.specifications) {
					dispatch(setSpecificationUncsPaginationCount(0))
					if (selectedProject?.unc_sort == 2024) {
						dispatch(
							getUncTechnicalSpecificationsCommonProject(
								urlId.current,
								100,
								specificationUncsPaginationCount,
								false,
								true
							)
						)
					} else if (selectedProject?.unc_sort) {
						dispatch(getUncTechnicalSpecificationsCommonProject(urlId.current, undefined, undefined, false, true))
					}
					setIsFetched(prev => ({ ...prev, specifications: true }))
				}
			}
		} finally {
			setIsFetched(prev => ({ ...prev, specifications: false, rates: false }))
		}
	}, [pathname, selectedProject?.unc_sort, selectedProject?.id])

	useEffect(() => {
		urlId.current = pathname.replace(/[^\d]/g, '')

		const checkUrl =
			pathname.includes('parameters') || pathname.includes('rates') || pathname.includes('construction-resources')

		if (pathRoute === Routes.COMMON_PROJECTS && checkUrl && urlId.current) {
			dispatch(getSelectCommonProject(urlId.current))
		}

		const routeCommonProjectsParameters = Routes.COMMON_PROJECTS_PARAMETERS.replace(/:id/g, urlId.current)

		const routeCommonProjectsUnnormalized = Routes.COMMON_PROJECTS_UNNORMALIZED.replace(/:id/g, urlId.current)

		const routeCommonProjectsRates = Routes.COMMON_PROJECTS_RATES.replace(/:id/g, urlId.current)

		const routeCommonProjectsConstructionResources = Routes.COMMON_PROJECTS_CONSTRUCTION_RESOURCES.replace(
			/:id/g,
			urlId.current
		)

		const routeUserProjectsRates = Routes.USER_PROJECTS_RATES.replace(/:id/g, urlId.current)
		const routeUserProjectsParameters = Routes.USER_PROJECTS_PARAMETERS.replace(/:id/g, urlId.current)

		const routePurchase = [PurchasesTypeEnum.Plan, PurchasesTypeEnum.Notice].map(type =>
			Routes.PURCHASES_DETAIL.replace(/:id/g, urlId?.current?.toString()!).replace(/:type/g, type)
		)

		if (
			pathname === routeCommonProjectsParameters &&
			groups &&
			selectedProject &&
			selectedProject?.groupId !== '' &&
			selectedProject?.groupId !== undefined
		) {
			setGroup(searchByKey(groups, 'id', selectedProject?.groupId))
		}

		if (routePurchase.includes(pathname)) {
			setGroup(searchByKey(company, 'id', selectedPurchase?.company_id))
		}

		const typesRoute = [
			{
				title: 'Параметры',
				path: pathRoute === Routes.COMMON_PROJECTS ? routeCommonProjectsParameters : routeUserProjectsParameters,
				tags: [Routes.COMMON_PROJECTS, Routes.USER_PROJECTS],
			},
			{
				title: 'Расценки',
				path: pathRoute === Routes.COMMON_PROJECTS ? routeCommonProjectsRates : routeUserProjectsRates,
				tags: [Routes.COMMON_PROJECTS, Routes.USER_PROJECTS],
			},
			...(unnormalizedData?.length
				? [
						{
							title: 'ННЗ',
							path:
								pathRoute === Routes.COMMON_PROJECTS_UNNORMALIZED
									? routeCommonProjectsUnnormalized
									: routeCommonProjectsUnnormalized,
							tags: [Routes.COMMON_PROJECTS],
						},
				  ]
				: []),
			{
				title: 'Строительные ресурсы',
				path: routeCommonProjectsConstructionResources,
				tags: [Routes.COMMON_PROJECTS],
			},
		]

		setTypesRoute(typesRoute)
	}, [unnormalizedData])

	return (
		<>
			<div className='container'>
				<BreadcrumbsComponent
					typesRoute={typesRoute as TypesNavLink[]}
					group={groups[group as number] || originalObj}
					path={pathRoute && pathRoute}
					nameLink={nameLink}
					urlId={urlId.current}
					nameProject={selectedProject?.code}
				/>
			</div>
			{children}
		</>
	)
})
export default SelectedContainer
