import { Grid } from '@material-ui/core'
import { convertFilterValue } from '@root-gipro/modules/filter/helpers/filter-state-convert'
import {
	refreshFilterState,
	showFilterResult as showFilterResultHandler,
} from '@root-gipro/modules/filter/store/actions'
import style from '@root-gipro/modules/header/styles/HeaderNav.module.scss'
import '@root-gipro/modules/header/styles/HeaderNavSubmenu.scss'
import { setPurchasesPaginationCounter } from '@root-gipro/modules/purchases/store/actions'
import { Routes } from '@root-gipro/router'
import { IState } from '@root-gipro/store/interfaces'
import { encode } from '@root-gipro/utils/helpers/queryString'
import React, { memo, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useHistory } from 'react-router-dom'

const HeaderNav: React.FC = memo(() => {
	const role = Number(window.localStorage.getItem('roleId'))

	const dispatch = useDispatch()
	const history = useHistory()
	const { filter } = useSelector((state: IState) => state)
	const { toggleType } = useSelector((state: IState) => state.favorites)
	let { showFilterState, countResult } = useSelector((state: IState) => filter.commonProjects)
	const publicInfo = useSelector((state: IState) => state.public)
	let rgStatuses = useSelector((state: IState) => state.public.rgStatuses)
	let {versionIprList} = useSelector((state: IState) => state.filter)

	rgStatuses = [
		...rgStatuses,
		{
			id: '',
			title: 'Запрос ИД', // доработка, с бэка не прилетает данный элемент
		},
	]

	const [adminMenuIsVisible, setAdminMenuIsVisible] = useState(false)
	const [adminSubMenuClass, setAdminSubMenuClass] = useState('nav-submenu')
	const menuRef = useRef(null)

	useEffect(() => {
		if (adminMenuIsVisible) {
			setAdminSubMenuClass('nav-submenu nav-submenu--active')
		} else {
			setAdminSubMenuClass('nav-submenu')
		}
	}, [adminMenuIsVisible])

	useEffect(() => {
		const handleClickOutside = (event: any) => {
			const menuElement = document.querySelector('.nav-submenu')
			if (adminMenuIsVisible && menuElement && !menuElement.contains(event.target)) {
				setAdminMenuIsVisible(!adminMenuIsVisible)
				setAdminSubMenuClass('nav-submenu')
			}
		}

		document.addEventListener('mousedown', handleClickOutside)

		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [adminMenuIsVisible])

	const changeToSipr = () => {
		dispatch(
			refreshFilterState(
				'code',
				'СиПР',
				'common-projects',
				convertFilterValue(
					publicInfo.regions,
					publicInfo.groups,
					publicInfo.company,
					publicInfo.uncThs,
					publicInfo.uncThDescriptions,
					rgStatuses,
					publicInfo.uncMains,


				)
			)
		)
	}

	const d = new Date()
	const dataloader =
		'/dataloader/?' +
		d.getDay() +
		'' +
		Math.floor(Math.random() * 10) +
		'' +
		d.getHours() +
		'' +
		Math.floor(Math.random() * 10) +
		'' +
		d.getDate()

	return (
		<Grid>
			<NavLink
				to={toggleType === 'PROJECTS' ? Routes.FAVORITES_COMMON_PROJECTS : Routes.FAVORITES_PURCHASES}
				isActive={(_, { pathname }) =>
					[`${Routes.FAVORITES_COMMON_PROJECTS}`, `${Routes.FAVORITES_PURCHASES}`].includes(pathname)
				}
				activeClassName={style.active}
				className={style.navLink}
			>
				Избранное
			</NavLink>

			<NavLink to={Routes.COMMON_PROJECTS} activeClassName={style.active} className={style.navLink}>
				Проекты
			</NavLink>
			<NavLink to={Routes.PURCHASES} activeClassName={style.active} className={style.navLink}>
				Закупки
			</NavLink>

			<NavLink to={Routes.COMMON_PROJECTS} onClick={changeToSipr} className={style.buttonLink}>
				СиПР
			</NavLink>

			{role && role <= 10 ? (
				<div className={style.navLink} onClick={() => setAdminMenuIsVisible(!adminMenuIsVisible)}>
					Администратор <small>&#9660;</small>
					<div ref={menuRef} className={adminSubMenuClass}>
						<a href={dataloader} className={style.navLink} target='_blank' rel='noopener noreferrer'>
							Загрузка данных
						</a>
						<NavLink to={Routes.ADMIN_INVESTMENT_PROGRAMS} activeClassName={style.active} className={style.navLink}>
							ИПР
						</NavLink>
						<NavLink
							to={Routes.ADMIN_DEVELOPMENT_SCHEMES_AND_PROGRAMS}
							activeClassName={style.active}
							className={style.navLink}
						>
							СиПР
						</NavLink>
						<NavLink to={Routes.ADMIN_STATISTICS} activeClassName={style.active} className={style.navLink}>
							Статистика
						</NavLink>
					</div>
				</div>
			) : null}
		</Grid>
	)
})

export default HeaderNav
