import Faq from '@root-gipro/components/Faq'
import YMetrika from '@root-gipro/components/YMetrika/YMetrika'
import { ModalProvider } from '@root-gipro/context/modal-provider'
import AdminCreateDevelopmentSchemeAndProgram from '@root-gipro/modules/admin/developmentSchemesAndPrograms/pages/Create'
import AdminEditDevelopmentSchemeAndProgram from '@root-gipro/modules/admin/developmentSchemesAndPrograms/pages/Edit'
import AdminDevelopmentSchemesAndPrograms from '@root-gipro/modules/admin/developmentSchemesAndPrograms/pages/Table'
import AdminCreateInvestmentProgram from '@root-gipro/modules/admin/investmentPrograms/pages/Create'
import AdminEditInvestmentProgram from '@root-gipro/modules/admin/investmentPrograms/pages/Edit'
import Statistics from '@root-gipro/modules/admin/investmentPrograms/pages/Statistics'
import AdminInvestmentPrograms from '@root-gipro/modules/admin/investmentPrograms/pages/Table'
import '@root-gipro/modules/admin/styles/Admin.scss'
import CommonProjectsContainer from '@root-gipro/modules/commonProjects/CommonProjectsContainer'
import {
	ConstructionResources,
	Parameters,
	Unnormalized,
	Rates,
	SelectedContainer,
} from '@root-gipro/modules/commonProjects/containers/SelectedProject'
import '@root-gipro/modules/commonProjects/styles/Projects.scss'
import { FavoritesContainer } from '@root-gipro/modules/favorites/containers/FavoritesContainer'
import Header from '@root-gipro/modules/header/containers/'
import { Notify } from '@root-gipro/modules/notify/Notify'
import PurchaseDetail from '@root-gipro/modules/purchases/containers/PurchaseDetail'
import PurchasesContainer from '@root-gipro/modules/purchases/PurchasesContainer'
import PurchasesTableContainer from '@root-gipro/modules/purchases/PurchasesTableContainer'
import { TariffsContainer } from '@root-gipro/modules/tariffs/TariffsContainer'
import { history, Routes } from '@root-gipro/router'
import { authorize, fetchPublicInfo } from '@root-gipro/store/actions'
import { IState } from '@root-gipro/store/interfaces/state'
import '@root-gipro/styles/index.scss'
import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, Router, Switch } from 'react-router-dom'

const App: FC = () => {
	const { isFilterOpen } = useSelector((state: IState) => state.maket)
	const { accessToken } = useSelector((state: IState) => state.auth)
	const dispatch = useDispatch()

	useEffect(() => {
		if (accessToken) {
			dispatch(fetchPublicInfo())
		} else {
			dispatch(authorize())
		}
	}, [accessToken])

	return (
		<Router history={history}>
			{accessToken && (
				<ModalProvider>
					<div className={`wrapper-for-animation ${isFilterOpen && 'is-filter-open'}`}>
						<Header />
						<div>
							<Switch>
								<Route exact path='/' render={() => <Redirect to={Routes.COMMON_PROJECTS} />} />
								<Route exact path={Routes.TARIFFS} component={TariffsContainer} />
								<Route exact path={Routes.COMMON_PROJECTS} component={CommonProjectsContainer} />
								<Route exact path={Routes.COMMON_PROJECTS_TABLE} component={CommonProjectsContainer} />

								{/* <Route exact path={Routes.USER_PROJECTS} component={UserProjects} /> */}
								<Route exact path={Routes.PURCHASES} component={PurchasesContainer} />
								<Route exact path={Routes.PURCHASES_TABLE} component={PurchasesTableContainer} />

								{/* <Route exact path={Routes.INVESTMENT_PROGRAMS} component={InvestmentPrograms} /> */}

								{/* <Route exact path={Routes.DEVELOPMENT_SCHEMES_AND_PROGRAMS} component={DevelopmentSchemesAndPrograms} /> */}

								<Route exact path={Routes.ADMIN_INVESTMENT_PROGRAMS} component={AdminInvestmentPrograms} />
								<Route exact path={Routes.ADMIN_STATISTICS} component={Statistics} />

								<Route
									exact
									path={Routes.ADMIN_DEVELOPMENT_SCHEMES_AND_PROGRAMS}
									component={AdminDevelopmentSchemesAndPrograms}
								/>

								<Route exact path={Routes.ADMIN_CREATE_INVESTMENT_PROGRAM} component={AdminCreateInvestmentProgram} />
								<Route
									exact
									path={Routes.ADMIN_CREATE_DEVELOPMENT_SCHEME_AND_PROGRAM}
									component={AdminCreateDevelopmentSchemeAndProgram}
								/>

								<Route exact path={Routes.ADMIN_EDIT_INVESTMENT_PROGRAM} component={AdminEditInvestmentProgram} />
								<Route
									exact
									path={Routes.ADMIN_EDIT_DEVELOPMENT_SCHEME_AND_PROGRAM}
									component={AdminEditDevelopmentSchemeAndProgram}
								/>

								<Route exact path={Routes.FAVORITES_COMMON_PROJECTS} component={FavoritesContainer} />
								<Route exact path={Routes.FAVORITES_COMMON_PROJECTS_TABLE} component={FavoritesContainer} />

								<Route exact path={Routes.FAVORITES_PURCHASES} component={FavoritesContainer} />
								<Route exact path={Routes.FAVORITES_PURCHASES_TABLE} component={FavoritesContainer} />

								<SelectedContainer>
									<Route exact path={Routes.PURCHASES_DETAIL} component={PurchaseDetail} />
									<Route exact path={Routes.COMMON_PROJECTS_PARAMETERS} component={Parameters} />
									<Route exact path={Routes.COMMON_PROJECTS_UNNORMALIZED} component={Unnormalized} />
									<Route exact path={Routes.COMMON_PROJECTS_RATES} component={Rates} />
									<Route exact path={Routes.COMMON_PROJECTS_CONSTRUCTION_RESOURCES} component={ConstructionResources} />

									{/* <Route exact path={Routes.USER_PROJECTS_PARAMETERS} component={CheckPlate} /> */}
									{/* <Route exact path={Routes.USER_PROJECTS_RATES} component={UncTablContainer} /> */}
								</SelectedContainer>
							</Switch>
						</div>
						<Faq />
						<Notify />
						<YMetrika />
					</div>
				</ModalProvider>
			)}
		</Router>
	)
}

export default App
