import { IFormData } from '@root-gipro/modules/commonProjects/models/interfaces/cost-works.state'
import {
	IValuationOfferForecast,
	IVisitsLog,
} from '@root-gipro/modules/commonProjects/models/interfaces/reports.models'
import { ICommonProject, IUnc, IUncInfo } from '@root-gipro/store/interfaces'
import { ReactText } from 'react'
import { CommonProjectsType } from '../models/interfaces/common-projects.actions'

const actionFavoriteCommonProjects = (projectId: string | 'multiple', action: 'DELETE' | 'POST', project: any) => ({
	type: CommonProjectsType.ACTIONS_FAVORITE_COMMON_PROJECTS,
	projectId,
	action,
	project,
})

const actionFavoriteCommonProjectsAndChangePurchases = (
	projectId: string | 'multiple',
	action: 'DELETE' | 'POST',
	project: any
) => ({
	type: CommonProjectsType.GET_FAVORITES_PROJECTS_AND_REMOVE_PURCHASES,
	projectId,
	action,
	project,
})

const getAllFavoriteCommonProjects = () => ({
	type: CommonProjectsType.GET_ALL_FAVORITE_COMMON_PROJECTS,
})

const getCommonProjects = () => ({
	type: CommonProjectsType.GET_COMMON_PROJECTS,
})

const setCommonProjects = (projects: ICommonProject[]) => ({
	type: CommonProjectsType.SET_COMMON_PROJECTS,
	projects,
})
const switchRatesFiltred = (uncRatesFiltred: boolean) => ({
	type: CommonProjectsType.SWITCH_RATES_FILTRED,
	uncRatesFiltred,
})
const addCommonProjects = (projects: ICommonProject[]) => ({
	type: CommonProjectsType.ADD_COMMON_PROJECTS,
	projects,
})

const setCommonProjectsSum = (valuationOfferForecastSum: number, valuationPlanForecastSum: number) => ({
	type: CommonProjectsType.SET_COMMON_PROJECTS_SUM,
	valuationOfferForecastSum,
	valuationPlanForecastSum,
})

const setCommonProjectsCount = (projectsCount: number) => ({
	type: CommonProjectsType.SET_COMMON_PROJECTS_COUNT,
	projectsCount,
})

const appendCommonProjects = (projectsCount: number) => ({
	type: CommonProjectsType.APPEND_COMMON_PROJECTS,
	projectsCount,
})

const setCommonProjectsPaginationCount = (paginationCounter: number) => ({
	type: CommonProjectsType.SET_COMMON_PROJECTS_PAGINATION_COUNT,
	paginationCounter,
})

const loadingCommonProjects = (loading: boolean) => ({
	type: CommonProjectsType.LOADING_COMMON_PROJECTS,
	loading,
})

const editToggleType = (toggleType: string) => ({
	type: CommonProjectsType.EDIT_TOGGLE_TYPE,
	toggleType,
})

const sortCommonProjects = (name: string, sortName: string, order?: boolean) => ({
	type: CommonProjectsType.SORT_COMMON_PROJECTS,
	name,
	sortName,
	order,
})
const downloadTableCommonProjects = (
	name: string,
	sortName: string,
	downloadType: string,
	order?: boolean | undefined
) => ({
	type: CommonProjectsType.DOWNlOAD_COMMON_PROJECTS,
	name,
	sortName,
	order,
	downloadType,
})
const reportRequest = (params: IValuationOfferForecast | IVisitsLog) => ({
	type: CommonProjectsType.REPORT_REQUEST,
	params,
})

const userInfoAction = (payload: any) => ({
	type: CommonProjectsType.USERINFO_REQUEST,
	payload,
})

const setUserInfoReport = (payload: any) => ({
	type: CommonProjectsType.USERINFO_REQUEST,
	payload,
})

const setReporting = (payload: any) => ({
	type: CommonProjectsType.REPORT_LOADING,
	payload,
})
const setTableDownloading = (payload: any) => ({
	type: CommonProjectsType.TABLE_DOWNLOAD_LOADING,
	payload,
})

const selectCommonProject = (selectedProject: ICommonProject | null) => ({
	type: CommonProjectsType.COMMON_PROJECTS_SELECTED_PROJECT,
	selectedProject,
})

const setUncInfo = (uncInfo: IUncInfo | null) => ({
	type: CommonProjectsType.SET_UNC_INFO,
	uncInfo,
})

const getUncsCommonProject = (id: string | number, limit?: number | undefined, paginationUncsCounter?: number | undefined, fetched?: boolean, newState?: boolean) => ({
	type: CommonProjectsType.GET_UNCS_COMMON_PROJECT,
	id,
	limit,
	paginationUncsCounter,
	fetched,
	newState
})

const getUnnormalizedCommonProjects = (id: string | number) => ({
	type: CommonProjectsType.GET_UNNORMALIZED_COMMON_PROJECT,
	id,
})
const setUnnormalizedCommonProjects = (unnormalizedData: []) => ({
	type: CommonProjectsType.SET_UNNORMALIZED_COMMON_PROJECT,
	unnormalizedData,
})
const loadingUnnormalizedCommonProjects = (load: boolean) => ({
	type: CommonProjectsType.LOAD_UNNORMALIZED_COMMON_PROJECT,
	load,
})

const getUncTechnicalSpecificationsCommonProject = (id: string | number, limit?: number, specificationUncsPaginationCount?: number | undefined, fetched?: boolean, newState?: boolean) => ({
	type: CommonProjectsType.GET_UNCS_TEHNICAL_SPECIFICATONS_COMMON_PROJECT,
	id,
	limit,
	specificationUncsPaginationCount,
	fetched,
	newState
})
const setUncTechnicalSpecificationsCommonProject = (uncTechnicalSpecifications: string[] | []) => ({
	type: CommonProjectsType.SET_UNCS_TEHNICAL_SPECIFICATONS_COMMON_PROJECT,
	uncTechnicalSpecifications,
})
const getSelectCommonProject = (id: string | number) => ({
	type: CommonProjectsType.SELECT_COMMON_PROJECT,
	id,
})

const setUncsCommonProject = (uncs: IUnc | []) => ({
	type: CommonProjectsType.SET_UNCS_COMMON_PROJECT,
	uncs,
})

const downloadCommonProject = (id: ReactText, code: string) => ({
	type: CommonProjectsType.DOWNLOAD_COMMON_PROJECT,
	id,
	code,
})

const downloadUncTechnicalSpecifications = (id: ReactText, code: string) => ({
	type: CommonProjectsType.DOWNLOAD_UNCS_TEHNICAL_SPECIFICATONS_COMMON_PROJECT,
	id,
	code,
})

const addToUserProject = (projectId: string | number) => ({
	type: CommonProjectsType.ADD_TO_USER_PROJECT,
	projectId,
})

const requestRgStatuses = (projectId: string | number) => ({
	type: CommonProjectsType.REQUEST_RG_STATUSES,
	projectId,
})

const setAllowMoreProjects = (allowMoreProjects: boolean) => ({
	type: CommonProjectsType.SET_ALLOW_MORE_PROJECTS,
	allowMoreProjects,
})

export const subscribeRequest = (data: IFormData) => ({
	type: CommonProjectsType.SUBSCRIBE_REQUEST,
	data,
})

export const subscribeSuccess = (result: unknown) => ({
	type: CommonProjectsType.SUBSCRIBE_SUCCESS,
	result,
})

export const subscribeFailure = (message: string) => ({
	type: CommonProjectsType.SUBSCRIBE_FAILURE,
	message,
})

export const setFilterIdsCommonProjects = (IDs: string[]) => ({
	type: CommonProjectsType.SET_FILTER_IDS_COMMON_PROJECTS,
	IDs,
})

export const setTargetProjectId = (targetProjectId: string) => ({
	type: CommonProjectsType.SET_TARGET_ID_PROJECT,
	targetProjectId,
})

export const setLoadPagination = (loadPagination: boolean) => ({
	type: CommonProjectsType.LOADING_COMMON_PAGINATION,
	loadPagination,
})

export const setLoadUncs = (loadUncs: boolean) => ({
	type: CommonProjectsType.LOADING_COMMON_UNCS,
	loadUncs,
})

const downloadTargetReports = (name: string, sortName: string, order?: boolean | undefined) => ({
	type: CommonProjectsType.GET_TARGET_REPORTS,
	name,
	sortName,
	order,
})

const setCommonUncsPaginationCount = (paginationUncsCounter: number) => ({
	type: CommonProjectsType.SET_COMMON_UNC_PAGINATION_COUNT,
	paginationUncsCounter,
})

const setCommonUncsCount = (uncsCounter: number) => ({
	type: CommonProjectsType.SET_COMMON_UNC_COUNT,
	uncsCounter,
})

const setSpecificationUncsPaginationCount = (specificationUncsPaginationCount: number) => ({
	type: CommonProjectsType.SET_SPECIFICATION_UNC_PAGINATION_COUNT,
	specificationUncsPaginationCount,
})
const setSpecificationUncsCount = (specificationUncsCount: number) => ({
	type: CommonProjectsType.SET_SPECIFICATION_UNC_COUNT,
	specificationUncsCount,
})
export {
	actionFavoriteCommonProjects,
	actionFavoriteCommonProjectsAndChangePurchases,
	addCommonProjects,
	addToUserProject,
	appendCommonProjects,
	downloadCommonProject,
	downloadTableCommonProjects,
	downloadTargetReports,
	downloadUncTechnicalSpecifications,
	editToggleType,
	getAllFavoriteCommonProjects,
	getCommonProjects,
	getSelectCommonProject,
	getUncsCommonProject,
	getUncTechnicalSpecificationsCommonProject,
	getUnnormalizedCommonProjects,
	loadingCommonProjects,
	reportRequest,
	requestRgStatuses,
	selectCommonProject,
	setAllowMoreProjects,
	setCommonProjects,
	setCommonProjectsCount,
	setCommonProjectsPaginationCount,
	setCommonProjectsSum,
	setReporting,
	setTableDownloading,
	setUncInfo,
	setUncsCommonProject,
	setUncTechnicalSpecificationsCommonProject,
	setUnnormalizedCommonProjects,
	loadingUnnormalizedCommonProjects,
	setUserInfoReport,
	sortCommonProjects,
	switchRatesFiltred,
	userInfoAction,
	setCommonUncsPaginationCount,
	setSpecificationUncsPaginationCount,
	setCommonUncsCount,
	setSpecificationUncsCount
}
