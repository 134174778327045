import React, { FC, MouseEvent, MutableRefObject, useCallback, useEffect, useRef, useState } from 'react'
import { Row } from './Row'
import { IState } from '@root-gipro/store/interfaces'
import UncRow from './UncRow'
import { useDispatch, useSelector } from 'react-redux'
import {
	getUncsCommonProject,
	getUncTechnicalSpecificationsCommonProject,
} from '@root-gipro/modules/commonProjects/store/actions'
import { useLocation } from 'react-router-dom'
import Loader from '@root-gipro/shared/Loader/Loader'

export type Cell = {
	value: (value: any) => number | string | false | undefined | JSX.Element
	textAlign: string
	tooltip?: string
}
export type TableType = {
	[key: string]: any
}

export interface TbodyProps {
	titleData?: string[]
	tableObj: TableType
	handleShowComment?(event: MouseEvent<HTMLDivElement>, comment: string): void
	getNumberUnc?: ((id: string) => string | number | false) | undefined
	cellData: (
		getNumberUnc?: ((id: string) => string | number | false) | any,
		handleShowComment?: (event: MouseEvent<HTMLDivElement>, comment: string) => void
	) => Cell[]
	gridTemplateColumns: string
	// selectedProject?: ICommonProject | null
	filteredUncs?: any
}

const Tbody: FC<TbodyProps> = ({
	gridTemplateColumns,
	cellData,
	titleData,
	tableObj,
	handleShowComment,
	getNumberUnc,
	// selectedProject,
	filteredUncs,
}) => {
	const LIMIT = 100
	const body = Object.entries(tableObj)
	const dispatch = useDispatch()
	const { pathname } = useLocation()
	const urlId: MutableRefObject<string | undefined> = useRef()
	const [isFetching, setIsFetching] = useState<boolean>(false)
	const { paginationUncsCounter, specificationUncsPaginationCount, loadUncs } = useSelector(
		(state: IState) => state.commonProjects
	)
	const specificationUncsCount = useSelector((state: IState) => state.commonProjects.specificationUncsCount)
	const uncsCounter = useSelector((state: IState) => state.commonProjects.uncsCounter)
	const selectedProject = useSelector((state: IState) => state.commonProjects.selectedProject)

	urlId.current = pathname.replace(/[^\d]/g, '')

	const onScroll = useCallback(async () => {
		if (loadUncs || isFetching) return

		const { scrollTop, scrollHeight } = document.documentElement
		const clientHeight = window.innerHeight
		const nearBottom = scrollHeight - scrollTop <= clientHeight + 10
		if (pathname.includes('construction-resources') && selectedProject?.unc_sort && selectedProject?.unc_sort == 2024) {
			if (nearBottom && urlId.current && specificationUncsCount > body[0][1]?.length) {
				setIsFetching(true)
				try {
					if (specificationUncsPaginationCount !== undefined)
						await dispatch(
							getUncTechnicalSpecificationsCommonProject(
								urlId.current,
								LIMIT,
								specificationUncsPaginationCount + 1,
								isFetching
							)
						)
				} finally {
					setIsFetching(false)
				}
			}
		} else if (pathname.includes('rates') && selectedProject?.unc_sort && selectedProject?.unc_sort == 2024) {
			if (nearBottom && urlId.current && uncsCounter > filteredUncs?.length) {
				setIsFetching(true)
				try {
					if (paginationUncsCounter !== undefined)
						await dispatch(getUncsCommonProject(urlId.current, LIMIT, paginationUncsCounter + 1, isFetching))
				} finally {
					setIsFetching(false)
				}
			}
		}
	}, [
		paginationUncsCounter,
		loadUncs,
		specificationUncsPaginationCount,
		pathname,
		specificationUncsCount,
		uncsCounter,
		body[0][1]?.length,
		filteredUncs?.length,
		selectedProject?.unc_sort,
	])

	useEffect(() => {
		const handleScroll = () => onScroll() // Обертка, чтобы гарантировать корректные зависимости
		window.addEventListener('scroll', handleScroll)

		return () => window.removeEventListener('scroll', handleScroll)
	}, [onScroll])

	return (
		<div>
			{selectedProject?.unc_sort && selectedProject?.unc_sort == 2024 && pathname.includes('rates')
				? filteredUncs?.map((item: any, i: number) => {
						return (
							<UncRow
								handleShowComment={handleShowComment}
								gridTemplateColumns={gridTemplateColumns}
								getNumberUnc={getNumberUnc}
								item={item}
								cellData={cellData}
								key={i}
								index={i}
							/>
						)
				  })
				: tableObj &&
				  body?.map((item: TableType, index: number) => (
						<Row
							handleShowComment={handleShowComment}
							gridTemplateColumns={gridTemplateColumns}
							getNumberUnc={getNumberUnc}
							title={titleData && titleData[index]}
							uncs={item[1]}
							tableNum={item[0]}
							cellData={cellData}
							key={index}
						/>
				  ))}
			<div>{loadUncs && <Loader />}</div>
		</div>
	)
}

export { Tbody }
