// ЭТОТ ФАЙЛ НЕ ТРОГАТЬ !!!!!

import { IDevelopmentSchemesAndProgram } from '@root-gipro/modules/admin/developmentSchemesAndPrograms/models/interfaces/sipr'
import { IInvestmentProgram } from '@root-gipro/modules/admin/investmentPrograms/models/interfaces/investment-program'
import { IDevelopmentSchemesAndProgramsFilterState } from '@root-gipro/modules/developmentSchemesAndPrograms/models/interfaces/development-schemes-and-programs.model'
import { IInvestmentProgramsFilterState } from '@root-gipro/modules/investmentPrograms/models/interfaces/investment-programs.model'
import { showNotify } from '@root-gipro/modules/notify/store/actions'
import { store } from '@root-gipro/store'
import { handleAccessError } from '@root-gipro/utils/helpers/handleAccessError'
import { handlerErrors } from '@root-gipro/utils/helpers/handlerErrors'
import axios, { AxiosInstance, Method } from 'axios'
import QS from 'qs'
import interseptor from './auth.interseptor'

// const BACKEND_PROTOCOL = process.env.REACT_APP_TRANSFER_PROTOCOL ?? 'https'

const domain = (url: string) =>
	// url === '/authorize' || url === '/auth/logout' ? 'https://dev.auth.gi-pro.ru/api/v1' : 'https://dev.gi-pro.ru/ptk/v2'
	url === '/authorize' || url === '/auth/logout'
		? `https://${process.env.REACT_APP_ENV_AUTH}/api/v1`
		: `https://${process.env.REACT_APP_ENV}/ptk/v2`

// const modeURL = process.env.NODE_ENV === 'development' ? `//localhost:4000/` : `https://dev.auth.gi-pro.ru/`
const modeURL =
	process.env.NODE_ENV === 'development' ? `//localhost:4000/` : `https://${process.env.REACT_APP_ENV_AUTH}`
// Дефолтные заголовки с авторизацией
const fetchHeaders = () => {
	return {
		'X-Requested-With': 'XMLHttpRequest',
		'Content-Type': 'application/json',
		Authorization: `Bearer ${localStorage.getItem('access_token')}`,
	}
}
const fetchAuthHeaders = () => ({
	'X-Requested-With': 'XMLHttpRequest',
	'Content-Type': 'application/json',
	Authorization: `Bearer ${localStorage.getItem('service_auth_token')}`,
})

const authApp: AxiosInstance = axios.create({
	headers: fetchHeaders(),
	baseURL: `https://${process.env.REACT_APP_ENV_AUTH}/api/v1`,
	withCredentials: true,
})

const giproApp: AxiosInstance = axios.create({
	headers: fetchHeaders(),
	baseURL: `https://${process.env.REACT_APP_ENV}/ptk/v2/`,
	paramsSerializer: params => {
		return QS.stringify(params, { arrayFormat: 'comma' })
	},
})

interseptor(giproApp)

// Функция делает запрос по указанному url,
// callback - функция, которая возвращает ответ к нужному нам виду

const fetchData = (
	url: string,
	callback: any,
	method: Method = 'GET',
	data?: any,
	headers: object = fetchHeaders()
) => {
	const request = giproApp({
		method,
		url: `${domain(url) + url}`,
		headers,
		data,
	})
		.then(res => {
			if (res.status >= 200 && res.status < 300) {
				if (res.data.forbiddenAccess) handleAccessError(res.data.forbiddenAccess)
				return res.data
			} else {
				throw res
			}
		})
		.then(res => {
			if (process.env.NODE_ENV === 'development') {
				if (res.status === 'failure') {
					store.dispatch(
						showNotify({
							type: 'error',
							message: res.message,
						})
					)
					throw new Error(res)
				}
				if (res.error) {
					store.dispatch(
						showNotify({
							type: 'error',
							message: res.error,
						})
					)
					throw new Error(res)
				}
			}
			return callback(res)
		})
		.catch(err => {
			const { data } = err.response
			handlerErrors(data)
		})

	return request
}

const authorizeApi = (clientId: string = 'gipro') => {
	const data = {
		response_type: 'token',
		client_id: clientId,
		with: 'user',
	}

	return authApp({
		method: 'POST',
		url: '/authorize',
		data: JSON.stringify(data),
	})
		.then((res: any) => {
			if (res.data.forbiddenAccess) handleAccessError(res.data.forbiddenAccess)
			return res.data
		})
		.catch((error: any) => {
			console.log(error)
			// return (window.location.href = modeURL)
		})
}

const logoutApi = () => {
	return authApp({
		method: 'POST',
		url: '/auth/logout',
	})
		.then((res: any) => res.data)
		.catch((error: any) => error)
}

const commonProjectsApi = (params: any) => {
	return giproApp({
		method: 'GET',
		url: '/project-general-search',
		params,
	})
		.then((res: any) => {
			if (res.data.forbiddenAccess) handleAccessError(res.data.forbiddenAccess)
			return res.data
		})
		.catch((err: any) => {
			const { data } = err.response
			// Костыль для обработки несовместимых вариантов фильтра (временно), необходимо решить вопрос с обработкой запроса на сервере
			if (
				data.message.includes(
					"Unknown column 'uncCellUncTechnicalSpecification.uncTechnicalSpecificationId' in 'on clause"
				)
			) {
				handlerErrors({ message: 'Выбраны несовместимые варианты фильтра', requestId: '', status: '' })
			} else {
				handlerErrors(data)
			}
		})
}
const userProjectsApi = (params: any) => {
	return giproApp({
		method: 'GET',
		url: '/user-projects',
		params,
	}).then((res: any) => {
		if (res.data.forbiddenAccess) handleAccessError(res.data.forbiddenAccess)
		return res.data
	})
}

const getInvestmentPrograms = (query: IInvestmentProgramsFilterState) => {
	let queryObject = {}
	Object.keys(query).forEach(key => {
		// @ts-ignore
		if (query[key]) {
			// @ts-ignore
			queryObject[key] = query[key]
		}
	})
	const queryParams = new URLSearchParams(queryObject).toString()

	return giproApp({
		method: 'GET',
		url: `/ipr?${queryParams}`,
	})
		.then((res: any) => {
			if (res.data.forbiddenAccess) handleAccessError(res.data.forbiddenAccess)
			return res.data
		})
		.catch((err: any) => {
			const { data } = err.response
			handlerErrors(data)
		})
}

const getInvestmentProgram = (id: string | number) => {
	return giproApp({
		method: 'GET',
		url: `/ipr/${id}`,
	})
		.then((res: any) => {
			if (res.data.forbiddenAccess) handleAccessError(res.data.forbiddenAccess)
			return res.data.ipr
		})
		.catch((err: any) => {
			const { data } = err.response
			handlerErrors(data)
		})
}
const getStatistics = () => {
	return giproApp({
		method: 'GET',
		url: `/statistics`,
	})
		.then((res: any) => {
			if (res.data.forbiddenAccess) handleAccessError(res.data.forbiddenAccess)
			return res
		})
		.catch((err: any) => {
			const { data } = err.response
			handlerErrors(data)
		})
}

const deleteInvestmentProgram = (id: string | number) => {
	return giproApp({
		method: 'DELETE',
		url: `/ipr/${id}`,
	})
		.then((res: any) => {
			if (res.data.forbiddenAccess) handleAccessError(res.data.forbiddenAccess)
			return res.data
		})
		.catch((err: any) => {
			const { data } = err.response
			handlerErrors(data)
		})
}

const updateInvestmentProgram = (id: string | number, program: IInvestmentProgram) => {
	return giproApp({
		method: 'PATCH',
		url: `/ipr/${id}`,
		data: program,
	})
		.then((res: any) => {
			if (res.data.forbiddenAccess) handleAccessError(res.data.forbiddenAccess)
			return res.data.ipr
		})
		.catch((err: any) => {
			const { data } = err.response
			handlerErrors(data)

			if (data.errors) {
				return { errors: data.errors }
			}
		})
}

const createInvestmentProgram = (program: any) => {
	return giproApp({
		method: 'POST',
		url: '/ipr',
		data: program,
	})
		.then((res: any) => {
			if (res.data.forbiddenAccess) handleAccessError(res.data.forbiddenAccess)
			return res.data.ipr
		})
		.catch((err: any) => {
			const { data } = err.response
			handlerErrors(data)

			if (data.errors) {
				return { errors: data.errors }
			}
		})
}

const getDevelopmentSchemesAndPrograms = (query: IDevelopmentSchemesAndProgramsFilterState) => {
	let queryObject = {}
	Object.keys(query).forEach(key => {
		// @ts-ignore
		if (query[key]) {
			// @ts-ignore
			queryObject[key] = query[key]
		}
	})
	const queryParams = new URLSearchParams(queryObject).toString()

	return giproApp({
		method: 'GET',
		url: `/sipr?${queryParams}`,
	})
		.then((res: any) => {
			if (res.data.forbiddenAccess) handleAccessError(res.data.forbiddenAccess)
			return res.data
		})
		.catch((err: any) => {
			const { data } = err.response
			handlerErrors(data)
		})
}

const getDevelopmentSchemesAndProgram = (id: string) => {
	return giproApp({
		method: 'GET',
		url: `/sipr/${id}`,
	})
		.then((res: any) => {
			if (res.data.forbiddenAccess) handleAccessError(res.data.forbiddenAccess)
			return res.data.sipr
		})
		.catch((err: any) => {
			const { data } = err.response
			handlerErrors(data)
		})
}

const deleteDevelopmentSchemesAndProgram = (id: string | number) => {
	return giproApp({
		method: 'DELETE',
		url: `/sipr/${id}`,
	})
		.then((res: any) => {
			if (res.data.forbiddenAccess) handleAccessError(res.data.forbiddenAccess)
			return res.data
		})
		.catch((err: any) => {
			const { data } = err.response
			handlerErrors(data)
		})
}

const updateDevelopmentSchemesAndProgram = (id: string | number, program: IDevelopmentSchemesAndProgram) => {
	return giproApp({
		method: 'PATCH',
		url: `/sipr/${id}`,
		data: program,
	})
		.then((res: any) => {
			if (res.data.forbiddenAccess) handleAccessError(res.data.forbiddenAccess)
			return res.data.sipr
		})
		.catch((err: any) => {
			const { data } = err.response
			handlerErrors(data)

			if (data.errors) {
				return { errors: data.errors }
			}
		})
}

const createDevelopmentSchemesAndProgram = (program: any) => {
	return giproApp({
		method: 'POST',
		url: '/sipr',
		data: program,
	})
		.then((res: any) => {
			if (res.data.forbiddenAccess) handleAccessError(res.data.forbiddenAccess)
			return res.data.sipr
		})
		.catch((err: any) => {
			const { data } = err.response
			handlerErrors(data)

			if (data.errors) {
				return { errors: data.errors }
			}
		})
}

const createMedia = (media: any) => {
	return giproApp({
		method: 'POST',
		url: '/media',
		data: media,
	})
		.then((res: any) => {
			if (res.data.forbiddenAccess) handleAccessError(res.data.forbiddenAccess)
			return res.data.media
		})
		.catch((err: any) => {
			const { data } = err.response
			handlerErrors(data)

			if (data.errors) {
				return { errors: data.errors }
			}
		})
}
const fetchMedia = (id: string) => {
	return giproApp({
		method: 'GET',
		url: `/media/${id}`,
	})
		.then((res: any) => {
			if (res.data.forbiddenAccess) handleAccessError(res.data.forbiddenAccess)
			return res.data.media
		})
		.catch((err: any) => {
			const { data } = err.response
			handlerErrors(data)

			if (data.errors) {
				return { errors: data.errors }
			}
		})
}

const getUncsCommonProjectApi = (params: any, id: string | number) => {
	return giproApp({
		method: 'GET',
		params,
		url: `/projects/${id}/uncs`,
	})
		.then((res: any) => {
			if (res.data.forbiddenAccess) handleAccessError(res.data.forbiddenAccess)
			return res.data
		})
		.catch((error: any) => error)
}

const getUncTechnicalSpecificationsCommonProjectApi = (id: string | number, params?: any) => {
	return giproApp({
		method: 'GET',
		url: `/projects/${id}/uncTechnicalSpecifications`,
		params
	})
		.then((res: any) => {
			if (res.data.forbiddenAccess) handleAccessError(res.data.forbiddenAccess)
			return res.data
		})
		.catch((error: any) => error)
}

const getSelectCommonProjectApi = (id: string | number) => {
	return giproApp({
		method: 'GET',
		url: `/projects/${id}?with=years`,
	})
		.then((res: any) => {
			if (res.data.forbiddenAccess) handleAccessError(res.data.forbiddenAccess)
			return res.data
		})
		.catch((error: any) => error)
}

export const getUnnormalizedApi = (id: string | number) => {
	return giproApp({
		method: 'GET',
		url: `/unnormalized?projectId=${id}`,
	})
		.then((res: any) => {
			if (res.data) {
				return res.data
			}
		})
		.catch((error: any) => error)
}

const downloadCommonProjectApi = (projectId: string | number, code: string, type: string) => {
	return giproApp({
		method: 'GET',
		url: `/projects/${projectId}/${type}`,
		responseType: 'blob',
	})
		.then((res: any) => {
			const url = URL.createObjectURL(new Blob([res.data]))
			const link = document.createElement('a')

			document.body.appendChild(link)
			link.href = url
			link.download = `${type}_${code}.xlsx`
			link.click()
			link.remove()
		})
		.catch((error: any) => error)
}

const getUserInfoWorker = (email: string) =>
	giproApp({
		method: 'GET',
		url: `https://${process.env.REACT_APP_ENV}/ptk/v2/tech-support/userInfo?email=${email}`,
	})
		.then((res: any) => {
			return res.data
		})
		.catch((error: any) => error)

const addToUserProjectApi = (projectId: string | number) =>
	giproApp({
		method: 'POST',
		url: `/user-projects`,
		data: JSON.stringify({ projectId }),
	})
		.then((res: any) => {
			if (res.data.forbiddenAccess) handleAccessError(res.data.forbiddenAccess)
			return res.data
		})
		.catch((error: any) => error)

const requestRgStatusesApi = (projectId: string | number) =>
	giproApp({
		method: 'POST',
		url: `/rg-statuses`,
		data: JSON.stringify({ projectId }),
	})
		.then((res: any) => {
			if (res.data.forbiddenAccess) handleAccessError(res.data.forbiddenAccess)
			return res.data
		})
		.catch((error: any) => error)

export {
	addToUserProjectApi,
	authApp,
	authorizeApi,
	commonProjectsApi,
	createDevelopmentSchemesAndProgram,
	createInvestmentProgram,
	createMedia,
	deleteDevelopmentSchemesAndProgram,
	deleteInvestmentProgram,
	domain,
	downloadCommonProjectApi,
	fetchAuthHeaders,
	fetchData,
	fetchHeaders,
	fetchMedia,
	getDevelopmentSchemesAndProgram,
	getDevelopmentSchemesAndPrograms,
	getInvestmentProgram,
	getInvestmentPrograms,
	getSelectCommonProjectApi,
	getStatistics,
	getUncsCommonProjectApi,
	getUncTechnicalSpecificationsCommonProjectApi,
	getUserInfoWorker,
	giproApp,
	logoutApi,
	modeURL,
	requestRgStatusesApi,
	updateDevelopmentSchemesAndProgram,
	updateInvestmentProgram,
	userProjectsApi,
}
