import React, { useState, useMemo, MouseEvent, useEffect } from 'react'
import { Collapse, Tooltip } from '@material-ui/core'
import { IState, IUnc } from '@root-gipro/store/interfaces'
import { prettyCost } from '@root-gipro/utils/helpers/common'
import cn from 'classnames'
import styles from '@root-gipro/shared/Table/styles/Table.module.scss'
import { Cell } from './Tbody'
import { useSelector } from 'react-redux'
import { decode } from 'querystring'
import { IUrlsSearch } from '@root-gipro/modules/filter/models/interfaces/filter-state.model'
import { isEmpty } from 'lodash'
import { useLocation } from 'react-router-dom'

export interface RowProps {
	getNumberUnc?: ((id: string) => string | number | false) | undefined
	title: string | undefined
	uncs: IUnc[]
	handleShowComment?(event: MouseEvent<HTMLDivElement>, comment: string): void
	tableNum: number
	cellData: (
		getNumberUnc?: ((id: string) => string | number | false) | undefined,
		handleShowComment?: (event: MouseEvent<HTMLDivElement>, comment: string) => void
	) => Cell[]
	gridTemplateColumns: string
}

const Row: React.FC<RowProps> = ({
	gridTemplateColumns,
	handleShowComment,
	title,
	uncs,
	tableNum,
	getNumberUnc,
	cellData,
}) => {
	const [open, setOpen] = useState<boolean>(!!uncs.length)
	const [uncCellIds, setUncCellIds] = useState<any>([])
	const handleShow = () => {
		setOpen(!open)
	}
	const uncRatesFiltred = useSelector((state: IState) => state.commonProjects.uncRatesFiltred)

	const total: number = useMemo(() => {
		if (uncs.length > 0) {
			return uncs.reduce((accum, curr) => (accum += curr.totalCost), 0)
		} else {
			return 0
		}
	}, [uncs])

	useEffect(() => {
		const urlsSearch: string | null = localStorage.getItem('urlsSearch')
		let urlsSearhParsed: IUrlsSearch = urlsSearch ? JSON.parse(urlsSearch) : {}

		if (!isEmpty(urlsSearhParsed['common-projects'])) {
			const decodUrl = decode(urlsSearhParsed['common-projects'] as string)

			if (decodUrl.uncCellId) {
				const ids = (decodUrl.uncCellId as string).split(',')
				setUncCellIds(ids)
			}
		}
	}, [])
	//--
	return (
		<>
			{title && (
				<div className={styles.row}>
					<div className={styles.rowTitle} onClick={handleShow}>
						<span
							className={cn('dropdown-icon', styles.arrow, {
								[styles.open]: open,
							})}
						/>
						{`${tableNum}. ${title}`}
					</div>
					<div className={styles.total}>{prettyCost(total / 1000)}</div>
				</div>
			)}

			<Collapse in={title ? open : true} unmountOnExit>
				{!!uncs.length ? (
					uncs.map((unc, i) => {
						if (!uncRatesFiltred) {
							return (
								<div
									style={{
										gridTemplateColumns: gridTemplateColumns,
									}}
									className={cn(styles.rowItem, styles.grid, {
										[styles.rowInFilter]: uncCellIds?.includes(unc.uncCellId),
									})}
									key={i}
								>
									{cellData(getNumberUnc, handleShowComment).map((cell: any, index: number) => {
										return (
										<Tooltip
											key={index}
											title={cell.tooltip ? cell.tooltip : cell.value(unc)}
											enterDelay={500}
											enterNextDelay={500}
											placement='top'
										>
											<span className={cn(styles.tableCell, styles[cell.textAlign])}>{cell.value(unc, i)}</span>
										</Tooltip>
									)
						})}
								</div>
							)
						} else {
							if (uncCellIds?.includes(unc.uncCellId)) {
								return (
									<div
										style={{
											gridTemplateColumns: gridTemplateColumns,
										}}
										className={cn(styles.rowItem, styles.grid)}
										key={i}
									>
										{cellData(getNumberUnc, handleShowComment).map((cell: any, index: number) => (
											<Tooltip
												key={index}
												title={cell.tooltip ? cell.tooltip : cell.value(unc)}
												enterDelay={500}
												enterNextDelay={500}
												placement='top'
											>
												<span className={cn(styles.tableCell, styles[cell.textAlign])}>{cell.value(unc, i)}</span>
											</Tooltip>
										))}
									</div>
								)
							}
						}
					})
				) : (
					<div className={styles.nothing}>Нет данных</div>
				)}
			</Collapse>
		</>
	)
}

export { Row }
