import React from 'react'
import { UncTechnicalSpecifications } from '@root-gipro/modules/commonProjects/models/interfaces/unc-technical-specifications.model'

export const cellData = (getNumberUnc: (id: string) => number | string) => [
	{
		value: (value: UncTechnicalSpecifications, index?: number) => index !== undefined && index + 1,
		textAlign: 'textAlignRight',
	},
	{
		value: (value: UncTechnicalSpecifications) => value.name,
		textAlign: 'textAlignLeft',
	},
	{
		value: (value: UncTechnicalSpecifications) => value.formattedParams,
		textAlign: 'textAlignLeft',
	},
	{
		value: (value: UncTechnicalSpecifications) => value.voltage,
		textAlign: 'textAlignLeft',
	},
	{
		value: (value: UncTechnicalSpecifications) => value.quantity,
		textAlign: 'textAlignLeft',
	},
	{
		value: (value: UncTechnicalSpecifications) => value.unit,
		textAlign: 'textAlignLeft',
	},
]
