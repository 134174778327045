import React, { useState, useMemo, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { UncSearch } from '@root-gipro/modules/userProjects/components/Project/UncAdd/UncSearch'
import { ToggleTabs } from '@root-gipro/modules/userProjects/components/Project/UncAdd/ToggleTabs'
import { PointElem } from '@root-gipro/modules/userProjects/components/Project/UncAdd/PointElem'
import ScrollArea from 'react-scrollbar'
import { IUncTableElem, IUncSection } from '@root-gipro/store/interfaces'
import { selectTableElem, fetchUncTableData } from '@root-gipro/modules/userProjects/store/actions'
import { IState } from '@root-gipro/store/interfaces/state'
import { itemsTab } from '@root-gipro/modules/userProjects/models/consts/unc-add-tabs.cons'
import { SecondaryBtn } from '@root-gipro/shared/Button/Button'
import { setFilterUncsModalState } from '@root-gipro/modules/filter/store/actions'
import { IPurchaseFilterState } from '@root-gipro/modules/purchases/models/interfaces/purchase-filter-state.model'

interface Props {
	filterState?: IPurchaseFilterState
}
export const UncList: React.FC<Props> = ({filterState}) => {
	const [activeList, setActiveList] = useState<string>(itemsTab[0].value)
	
	const { uncMains, uncSections } = useSelector((state: IState) => state.public)
	const { uncFilter } = useSelector((state: IState) => state.userProjects)
	const { versionIprList } = useSelector((state: IState) => state.filter)

	const versionIprActive = useMemo(()=>{
		if(filterState){
			if(filterState["iprVersionId"]){
				return filterState["iprVersionId"]
			}
		}
	},[filterState])
	const isFilterUncsModalState = useSelector((state: IState) => state.filter.isFilterUncsModalState)
	
	const [filteredSectionsList, setFilteredSectionsList] = useState<any>(uncSections)
	const dispatch = useDispatch()


	const sortedUncElems = useMemo(() => {
		// @ts-ignore
		const version = parseInt(versionIprList.find((vers: any)=>vers.id == versionIprActive).name.split('-')[1], 10);
    const filterVersion = version >= 2024 ? 2024 : 2018;

		const newArr = uncMains.slice()
		return newArr.filter((unc: any) => unc?.unc_sort === filterVersion).sort((a: IUncTableElem, b: IUncTableElem) => {
			if (a.code > b.code) return 1
			else return -1
		})
	}, [uncMains])

	

	const cancelHandler = () => {
		dispatch(setFilterUncsModalState(false))	
	}


function filterAndSortProjects() {
	// @ts-ignore
	const version = parseInt(versionIprList.find((vers: any)=>vers.id == versionIprActive).name.split('-')[1], 10);
	
    const filterVersion = version >= 2024 ? 2024 : 2018;
    return uncSections
        .filter(project => project.unc_sort === filterVersion)
      
}

useEffect(()=>{
	setFilteredSectionsList(filterAndSortProjects())
},[versionIprActive])
	return (
		<div className='uncs_list filter_side_open'>
			<div className='uncs_list__head flex space-between'>
				<h1>Таблицы расценок УНЦ</h1>
				<UncSearch setActiveList={setActiveList} />
			</div>
			<div className='options'>
				<ToggleTabs items={itemsTab} clickHandler={item => setActiveList(item.value)} />
			</div>
			<ScrollArea speed={0.8} smoothScrolling={true} style={{ height: 557 }}>
				{activeList === itemsTab[0].value ? (
					<>
						{filteredSectionsList
							.filter(
								(section: IUncSection) =>
									section.name.toLowerCase().match(uncFilter) || section.num.toString().toLowerCase().match(uncFilter)
							)
							.map((section: IUncSection) => (
								<PointElem key={section.num} name={section.name} num={section.num} uncMainIds={section.uncMainIds} />
							))}
					</>
				) : (
					<>
						{uncMains.length > 0 &&
							sortedUncElems
								.filter(
									(uncElem: IUncTableElem) =>
										uncElem.name.toLowerCase().match(uncFilter) || uncElem.code.toLowerCase().match(uncFilter)
								)
								.map((uncElem: IUncTableElem) => {
									if(!uncElem.code.includes("Ц")){
										return (
										
										<div
											onClick={() => {
												dispatch(selectTableElem(uncElem))
												dispatch(fetchUncTableData(uncElem.id))
											}}
											key={uncElem.id}
											className='uncs_list__item-table'
										>
											<span className='table_num'>{uncElem.code}. </span>
											{uncElem.name}
										</div>
									)

									}
								})}
					</>
				)}
			</ScrollArea>
			{isFilterUncsModalState ? (
				<div className='action_btns filter_side_btns mt-8'>
					<SecondaryBtn variant='contained' onClick={cancelHandler} className='fullWidth'>
						Закрыть
					</SecondaryBtn>
				</div>
			) : (
				''
			)}
		</div>
	)
}
