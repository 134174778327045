import React, { FC, MouseEvent, useEffect, useState } from 'react'
import styles from '@root-gipro/shared/Table/styles/Table.module.scss'
import cn from 'classnames'
import { Tooltip } from '@material-ui/core'
import { ICommonProject } from '@root-gipro/store/interfaces'
import { IUrlsSearch } from '@root-gipro/modules/filter/models/interfaces/filter-state.model'
import { isEmpty } from 'lodash'
import { decode } from 'querystring'
import { Cell } from './Tbody'

export interface IUncRow {
	handleShowComment?(event: MouseEvent<HTMLDivElement>, comment: string): void
	getNumberUnc?: ((id: string) => string | number | false) | undefined
	cellData: (
		getNumberUnc?: ((id: string) => string | number | false) | any,
		handleShowComment?: (event: MouseEvent<HTMLDivElement>, comment: string) => void
	) => Cell[]
	gridTemplateColumns: string
	selectedProject?: ICommonProject | null
	filteredUncs?: any
	item: any
    index: number
}

const UncRow: FC<IUncRow> = ({
	gridTemplateColumns,
	cellData,
	handleShowComment,
	getNumberUnc,
	selectedProject,
	item,
    index
}) => {
	const [uncCellIds, setUncCellIds] = useState<any>([])
	useEffect(() => {
		const urlsSearch: string | null = localStorage.getItem('urlsSearch')
		let urlsSearhParsed: IUrlsSearch = urlsSearch ? JSON.parse(urlsSearch) : {}

		if (!isEmpty(urlsSearhParsed['common-projects'])) {
			const decodUrl = decode(urlsSearhParsed['common-projects'] as string)

			if (decodUrl.uncCellId) {
				const ids = (decodUrl.uncCellId as string).split(',')
				setUncCellIds(ids)
			}
		}
	}, [])
	return (
		<div
			style={{
				gridTemplateColumns: gridTemplateColumns,
			}}
			className={cn(styles.rowItem, styles.grid, {
				[styles.rowInFilter]: uncCellIds?.includes(item.uncCellId),
			})}
			key={index}
		>
			{cellData(getNumberUnc, handleShowComment).map((cell: any, index: number) => {
				return (
					<Tooltip
						key={index}
						title={cell.tooltip ? cell.tooltip : cell.value(item)}
						enterDelay={500}
						enterNextDelay={500}
						placement='top'
					>
						<span className={cn(styles.tableCell, styles[cell.textAlign])}>
							{cell.value(item, index, selectedProject?.unc_sort && selectedProject?.unc_sort, true)}
						</span>
					</Tooltip>
				)
			})}
		</div>
	)
}

export default UncRow
