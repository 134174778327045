import {
	CircularProgress,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
	Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { IState } from '@root-gipro/store/interfaces'
import { prettyCost } from '@root-gipro/utils/helpers/common'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'

const useStyles = makeStyles(theme => ({
	tableContainer: {
		marginTop: theme.spacing(2),
		overflowX: 'auto', // Горизонтальный скролл
		maxWidth: '100%',
	},
	table: {
		minWidth: 1200, // Минимальная ширина таблицы для скролла
	},
	noData: {
		padding: theme.spacing(3),
		textAlign: 'center',
		fontSize: 16,
	},
	tableCell: {
		whiteSpace: 'pre-line',
		lineHeight: '110%',
		verticalAlign: 'top',
		fontSize: 12,
	},
}))

const TableHeader = ({ isExpanded }: { isExpanded: boolean }) => {
	const classes = useStyles()

	const headers = [
		{ text: '№', short: '№', width: 20 },
		{ text: 'Группа', short: 'Группа', width: 100 },
		{ text: 'Наименование', short: 'Наименование', width: 300 },
		{
			text: 'Наименование одного объекта, где реализуется технологическое решение (мероприятие)',
			short: 'Объект (мероприятие)',
			width: 200,
		},
		{
			text: 'Номер очереди (этапа) строительства (реализации проекта)',
			short: '№ этапа',
			width: 100,
		},
		{
			text: 'Наименование организации (лица), в отношении которого производится компенсация, переустройство',
			short: 'Организация (лицо)',
			width: 200,
		},
		{ text: 'Количество', short: 'Кол-во', width: 100 },
		{ text: 'Измеритель (единица измерения)', short: 'Ед. изм.', width: 120 },
		{ text: 'Номер сметного расчета', short: '№ сметы', width: 100 },
		{
			text:
				'Величина затрат в ценах, сложившихся ко времени составления сметной документации, тыс. рублей (с учетом налогов и сборов)',
			short: 'Затраты (тыс. руб.)',
			width: 200,
		},
		{
			text: 'Удельный показатель ненормируемых затрат тыс. рублей',
			short: 'Уд. ненорм. затраты',
			width: 180,
		},
		{ text: 'Краткое обоснование', short: 'Обоснование', width: 200 },
	]

	return (
		<TableHead>
			<TableRow>
				{headers.map((header, index) => (
					<TableCell
						key={index}
						width={header.width || 'auto'}
						className={classes.tableCell}
						style={{ backgroundColor: 'rgba(0, 0, 0, 0.03)', color: 'rgba(0, 0, 0, 0.34)' }}
					>
						{isExpanded ? (
							header.text
						) : (
							<Tooltip title={header.text} arrow>
								<span>{header.short}</span>
							</Tooltip>
						)}
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	)
}

const TruncatedCell = ({
	text,
	isExpanded,
	shortText,
}: {
	text: string | number
	isExpanded: boolean
	shortText?: boolean
}) => {
	const classes = useStyles()

	if (isExpanded) {
		// Полный текст без обрезки
		return (
			<TableCell align='left' className={classes.tableCell}>
				<Tooltip title={text} arrow>
					<span>{(shortText ? prettyCost(text) : text) || '-'}</span>
				</Tooltip>
			</TableCell>
		)
	}
	return (
		<TableCell align='left'>
			<Tooltip title={text} arrow>
				<span
					style={{
						display: '-webkit-box',
						WebkitBoxOrient: 'vertical',
						WebkitLineClamp: 2,
						overflow: 'hidden',
						minWidth: 15,
						textOverflow: 'ellipsis',
					}}
				>
					{shortText ? prettyCost(text) : text || '-'}
				</span>
			</Tooltip>
		</TableCell>
	)
}

export default function UnnormalizedTable() {
	const classes = useStyles()
	const { unnormalizedData, loadUnnormalizedData } = useSelector((state: IState) => state.commonProjects)

	const [isExpanded, setIsExpanded] = useState(false)

	const toggleExpand = () => {
		setIsExpanded(prev => !prev)
	}

	return (
		<TableContainer component={Paper} className={classes.tableContainer} onClick={toggleExpand}>
			{loadUnnormalizedData ? (
				<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 100 }}>
					<CircularProgress />
				</div>
			) : unnormalizedData && unnormalizedData?.length > 0 ? (
				<Table className={classes.table} size='small' aria-label='unnormalized data table'>
					<TableHeader isExpanded={isExpanded} />
					<TableBody>
						{unnormalizedData.map((row, index) => (
							<TableRow key={row.id}>
								<TruncatedCell isExpanded={isExpanded} text={index + 1} />

								<TruncatedCell isExpanded={isExpanded} text={row.data.unn_group} />
								<TruncatedCell isExpanded={isExpanded} text={row.data.unn_name} />
								<TruncatedCell isExpanded={isExpanded} text={row.data.object_name} />
								<TruncatedCell isExpanded={isExpanded} text={row.data.phase} />
								<TruncatedCell isExpanded={isExpanded} text={row.data.related} />
								<TruncatedCell isExpanded={isExpanded} shortText={true} text={row.data.count} />
								<TruncatedCell isExpanded={isExpanded} text={row.data.unit} />
								<TruncatedCell isExpanded={isExpanded} text={'-'} />
								<TruncatedCell isExpanded={isExpanded} shortText={true} text={row.data.unn_costs} />
								<TruncatedCell isExpanded={isExpanded} shortText={true} text={row.data.unn_k} />

								<TruncatedCell isExpanded={isExpanded} text={row.data.comment} />
							</TableRow>
						))}
					</TableBody>
				</Table>
			) : (
				<Typography className={classes.noData}>Данные отсутствуют</Typography>
			)}
		</TableContainer>
	)
}
