import { IFilterState, IFilterRates, IParams } from '@root-gipro/modules/filter/models/interfaces/filter-state.model'
import { IPurchasesList } from '@root-gipro/modules/purchases/models/interfaces/purchases-list-request'
import { ICommonProject, IConfigures, IPresetFilter, IUncCell } from '@root-gipro/store/interfaces'
import { FilterActions } from '@root-gipro/modules/filter/models/interfaces/filter.actions'
import { Action } from 'redux'

interface IRefreshList extends ICommonProject, IPurchasesList {}
export type { IRefreshList }

export const setCallbacks = (
	getInitList: () => void,
	showFilterResult: (params: IParams) => void,
	getVersionIprList: () => any,
	refreshList?: (projects: IRefreshList[], isFavorite: boolean) => Action,
	toggleLoadingList?: (loading: boolean) => void
) => ({
	type: FilterActions.SET_CALLBACKS,
	getInitList,
	showFilterResult,
	refreshList,
	toggleLoadingList,
	getVersionIprList
})
export const filtersRequest = (url: string) => ({
	type: FilterActions.FILTER_REQUEST,
	url,
})
export const filterSetResult = () => ({
	type: FilterActions.FILTER_SET_RESULT,
})
export const setFilterSortParams = (params: IParams, url: string) => ({
	type: FilterActions.SET_FILTER_SORT_PARAMS,
	params,
	url,
})

export const setSimilarProjectSearch = (commonProjectId: string, projectName: string | undefined) => ({
	type: FilterActions.SET_SIMILAR_PROJECT_SEARCH,
	commonProjectId,
	projectName,
})
export const isSimilarProjectFilterState = (isSimilar: boolean) => ({
	type: FilterActions.IS_SIMILAR_PROJECT_FILTER_STATE,
	isSimilar,
})
export const setSimilarProjectName = (name: string) => ({
	type: FilterActions.SET_SIMILAR_PROJECT_NAME,
	name,
})
export const disabledSimilarProjectFilterState = () => ({
	type: FilterActions.DISABLED_SIMILAR_PROJECT_FILTER_STATE,
})

export const refreshFilterState = (
	fieldName: string,
	value: string,
	url: string,
	convertValue: (fieldName: string, value: string) => { fieldName: string; value: string }
) => ({
	type: FilterActions.REFRESH_FILTER_STATE,
	fieldName,
	value,
	url,
	convertValue,
})
export const refreshFilterStateIprId = (
	fieldName: string,
	value: string,
) => ({
	type: FilterActions.REFRESH_FILTER_STATE_IPR_ID,
	fieldName,
	value,
})

export const showFilterResult = (url: string, filter?: boolean) => ({
	type: FilterActions.SHOW_FILTER_RESULT,
	url,
	filter,
})

export const resetFilterState = (url: string) => ({
	type: FilterActions.RESET_FILTER_STATE,
	url,
})

export const setInitFilterState = (url: string, state: any, revertFilterValue: (state: any) => {}) => ({
	type: FilterActions.SET_INIT_FILTER_STATE,
	url,
	state,
	revertFilterValue,
})

export const setFilterState = (state: IFilterState) => ({
	type: FilterActions.SET_FILTER_STATE,
	state,
})

export const setFilterResultCount = (count: number, url: string) => ({
	type: FilterActions.SET_FILTER_RESULT_COUNT,
	count,
	url,
})

export const setFilterCommonProjectsRates = (data: IFilterRates) => ({
	type: FilterActions.SET_FILTER_COMMON_PROJECTS_RATES,
	data,
})
export const resetFilterCommonProjectsRates = () => ({
	type: FilterActions.RESET_FILTER_COMMON_PROJECTS_RATES,
})
export const isLoadingCountResult = (isLoading: boolean) => ({
	type: FilterActions.IS_LOADING_COUNT_RESULT,
	isLoading,
})
export const isShowFilterCloud = (isShowFilterCloud: boolean) => ({
	type: FilterActions.IS_SHOW_FILTER_CLOUD,
	isShowFilterCloud,
})
export const setUncCodesFilter = (uncCodes: IUncCell[] | IUncCell) => ({
	type: FilterActions.SET_UNC_CODES_FILTER,
	uncCodes,
})
export const deleteUncCodeFromFilter = (unc: IUncCell) => ({
	type: FilterActions.DELETE_UNC_FROM_FILTER,
	unc,
})
export const cleanUncCodeFromFilter = () => ({
	type: FilterActions.CLEAN_UNC_FROM_FILTER,
})

export const setFilterUncsModalState = (isFilterUncsModalState: boolean) => ({
	type: FilterActions.SET_FILTER_MODAL_STATE,
	isFilterUncsModalState,
})
export const setFilterConfigureModalState = (isFilterConfigureModalState: boolean) => ({
	type: FilterActions.SET_FILTER_CONFIGURE_MODAL_STATE,
	isFilterConfigureModalState,
})
export const setFilterConfigures = (configures: IConfigures) => ({
	type: FilterActions.SET_FILTER_CONFIGURES,
	configures,
})
export const setActualFilter = (isActualFilter: number) => ({
	type: FilterActions.SET_ACTUAL_FILTER,
	isActualFilter,
})

export const setOpenPresetFilterModal = (isOpenPresetFilterModal: boolean, mode: 'save' | 'edit' | null) => ({
	type: FilterActions.SET_OPEN_PRESET_FILTER_MODAL,
	isOpenPresetFilterModal,
	mode,
})

export const setPresetFilterState = (dataPresetFilter: any) => ({
	type: FilterActions.SET_PRESET_FILTER_STATE,
	dataPresetFilter,
})

export const getPresetFilters = () => ({
	type: FilterActions.GET_PRESET_FILTERS,
})
export const savePresetFilters = (name: string, filters: any) => ({
	type: FilterActions.SAVE_PRESET_FILTER,
	name,
	filters,
})
export const editPresetFilters = (id: string, name: string, filters: any) => ({
	type: FilterActions.EDIT_PRESET_FILTER,
	id,
	name,
	filters,
})
export const deletePresetFilters = (id: string) => ({
	type: FilterActions.DELETE_PRESET_FILTERS,
	id,
})
export const setPresetFilters = (url: string, filters: any) => ({
	type: FilterActions.SET_PRESET_FILTERS,
	url,
	filters,
})
export const isSelectedPresetFilter = (selected: boolean) => ({
	type: FilterActions.IS_SELECTED_PRESET_FILTER,
	selected,
})

export const setSelectedPresetFilter = (filter: IPresetFilter | null) => ({
	type: FilterActions.IS_SELECTED_ID_PRESET_FILTER,
	filter,
})
export const setVersionIprList = (versionIprList: any) => ({
	type: FilterActions.SET_VERSION_IPR_LIST,
	versionIprList,
})
export const getVersionIprList = () => ({
	type: FilterActions.GET_VERSION_IPR_LIST,

})