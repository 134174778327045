import React, { memo, useEffect, useState } from 'react'

import { Button, CircularProgress, IconButton, makeStyles, TextField } from '@material-ui/core'
import { filter, intersectionBy, isEmpty, isEqual, matches } from 'lodash'
import { FilterSearchCheckboxesProps, ICheckboxItem } from '../models/filter-item-search-checkboxes.props'
import FilterItemCheckboxSearch from './FilterItemCheckboxSearch'
import { useLocation, useParams } from 'react-router-dom'
import { decode } from '@root-gipro/utils/helpers/queryString'
import { IUrlsSearch } from '@root-gipro/modules/filter/models/interfaces/filter-state.model'

const useStylesBtn = makeStyles(theme => ({
	root: {
		backgroundColor: 'transparent',
		fontWeight: 400,

		'&:hover': {
			backgroundColor: 'transparent',
			fontWeight: 500,
			// textDecoration: 'underline',
		},
	},
}))

const useStylesReddit = makeStyles(theme => ({
	root: {
		border: '1px solid #e2e2e1',
		overflow: 'hidden',
		borderRadius: 4,
		paddingRight: 0,
		height: 'auto',
		backgroundColor: '#fff',
		transition: theme.transitions.create(['border-color', 'box-shadow']),
		'&:hover': {
			backgroundColor: '#fff',
		},
		'&$focused': {
			backgroundColor: '#fff',
			borderColor: theme.palette.primary.main,
		},
		'& .MuiFilledInput-inputHiddenLabel.MuiFilledInput-inputMarginDense': {
			paddingTop: 7,
			paddingBottom: 6,
		},
		'& .MuiIconButton-root': {
			padding: '6px',
		},
	},
	focused: {},
}))

const FilterItemSearchCheckbox: React.FC<FilterSearchCheckboxesProps> = memo(({
	value,
	setValue,
	placeholder,
	isDisable,
	fieldName,
	items,
	isMultiple
}) => {
	const [checkedItems, setCheckboxItems] = useState(value || [])
	const [allCheckboxesItems, setAllCheckboxesItems] = useState<ICheckboxItem[]>([])
	const [query, setQuery] = useState('')
	const location = useLocation()
	const url: string = location.pathname.slice(1)

	const getParams = (): any => {
		const urlsSearch: string | null = localStorage.getItem('urlsSearch')
		let urlsSearhParsed: IUrlsSearch = urlsSearch ? JSON.parse(urlsSearch) : {}

		if (!isEmpty(urlsSearhParsed[url])) {
			return decode(urlsSearhParsed[url] as string)
		}
	}

	let params = getParams()

	const classes2 = useStylesBtn()
	const classes = useStylesReddit()

	useEffect(() => {
		// начальное значение
		const searchParams: any = params?.[fieldName] ? params?.[fieldName] : null
		if (items.length && searchParams) {
			const defaultChecked: any[] = []
			items.map(item => {
				if (
					searchParams.find((el: string) => {
						if (url === 'purchases' || url === 'purchases/table') {
							return el === item?.code
						} else {
							return el === item.id
						}
					})
				) {
					defaultChecked.push(item.name)
				}
				return item
			})
			setCheckboxItems(defaultChecked)
		}

		setAllCheckboxesItems(items)
	}, [items[0]])

	useEffect(() => {
		if (!isEqual(value, checkedItems)) {
			setCheckboxItems(value || [])
		}

		// при сбросе всех фильтров
		if (value == undefined) {
			setCheckboxItems([])
			setQuery('')
		}
	}, [value])

	useEffect(() => {
		setValue(fieldName, checkedItems)
	}, [checkedItems])

	const resetCheckedItems = () => {
		setCheckboxItems([])
		setQuery('')
	}

	const updateData = (value: string) => {
		if (isMultiple) {
			// Для множественного выбора
			if (checkedItems.indexOf(value) === -1) {
				setCheckboxItems([...checkedItems, value])
			} else {
				setCheckboxItems((prev: any[]) => prev.filter(prevItem => prevItem !== value))
			}
		} else {
			// Для одиночного выбора
			setCheckboxItems([value]) 
		}
	}

	return (
		<div style={{ position: 'relative' }}>
			{fieldName.split('.')[0] === 'uncCell' ? <h4 style={{ marginTop: '16px' }}>{placeholder}</h4> : null}
			{checkedItems.length ? (
				<Button
					type='reset'
					variant='text'
					size='small'
					color='primary'
					classes={classes2}
					className='aside-filter__reset link link-blue'
					style={{ textTransform: 'none', padding: 0 }}
					disableFocusRipple
					disableRipple
					onClick={resetCheckedItems}
				>
					Очистить
				</Button>
			) : null}
			<TextField
				value={query}
				placeholder='Поиск...'
				margin='dense'
				hiddenLabel
				variant='filled'
				InputProps={{
					classes,
					disableUnderline: true,
					endAdornment: query && (
						<>
							<IconButton type='button' onClick={() => setQuery('')} className='reset-icon'>
								<span className='close-icon' />
							</IconButton>
						</>
					),
				}}
				color='primary'
				onChange={e => setQuery(e.target.value)}
				fullWidth
			/>

			<div style={{ maxHeight: '230px', paddingBottom: '8px', overflowY: 'scroll', overflowX: 'hidden' }}>
				{allCheckboxesItems
					.filter((el: ICheckboxItem) => {
						if (query === '' || el.name.toLowerCase().trim().includes(query.toLowerCase().trim())) {
							return el
						}
					})
					.map((item: ICheckboxItem) => (
						<FilterItemCheckboxSearch
							key={item.id}
							value={false}
							queryValue={query.toLowerCase().trim()}
							name={item.name}
							projectsCount={item.projectsCount}
							fieldName={fieldName}
							isDisable={isDisable}
							updateData={updateData}
							isChecked={checkedItems} 
						/>
					))}
			</div>

			{allCheckboxesItems.length ? null : (
				<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '12px 0' }}>
					<CircularProgress size={24} style={{ color: 'primary' }} />
				</div>
			)}
		</div>
	)
})

export default FilterItemSearchCheckbox
